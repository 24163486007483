import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Metatags from "../components/metatags"
import Navbar from "../components/navbar"
import Footer from "../components/footer"

const WhoWeAre = ({ data }) => (
  <>
    <Metatags
      title="daucodesigns | quienes somos"
      description="detrás de cada producto, hay una historia"
      url="https://daucodesigns.com"
      image="https://daucodesigns.com/logo-daucodesigns.png"
      keywords="muebles, design"
    />
    <Navbar />
    <div className="height40" />

    <Container
      style={{ marginTop: "60px", maxWidth: "1300px" }}
      className="quienes-somos"
    >
      <Row>
        <Col>
          <div className="text-center">
            <h4 className="marcas-title nuestras-marcas-title">
              quienes somos
            </h4>
            <h4 id="marcas-slogan" style={{ color: "#606060" }}>
              las personas definen el espacio, los productos le dan vida
            </h4>
          </div>
        </Col>
      </Row>
      <div className="height40" />
      <Row>
        <Col sm={4}>
          <GatsbyImage
            image={getImage(data.quienesSomos1)}
            backgroundColor={false}
            alt="daucodesigns"
          />
        </Col>
        <Col sm={8}>
          <p>&nbsp;</p>
          <p>
            En daucodesigns, creemos que los interiores forman parte de nuestro
            estilo de vida, desde las oficinas en las que trabajamos hasta las
            tiendas que visitamos o los restaurantes en los que comemos. Cada
            uno de esos lugares no es sólo un edificio: es un lugar de trabajo,
            un espacio de relajación o un entorno de interacción para las
            personas. El estilo de nuestra vida y nuestro trabajo cambia, y con
            él los espacios en los que nos desenvolvemos. Para crear estos
            espacios, necesitamos diseño, mobiliario y profesionales con un
            enfoque innovador y creativo.
          </p>
          <p>
            <b>Personas responsables y productos responsables.</b>
          </p>
        </Col>
      </Row>
    </Container>
    <div className="height40" />
    <Row className="quienes-somos-block">
      <Container>
        <Row>
          <Col sm={2} />
          <Col sm={8}>
            <h4 className="quienes-somos-title">01 Experiencia</h4>
          </Col>
        </Row>
      </Container>
    </Row>
    <Container className="quienes-somos">
      <Row>
        <Col sm={2} />
        <Col sm={8}>
          <p>&nbsp;</p>
          <p>
            Durante más de 15 años hemos estado creando soluciones para hogares,
            oficinas, hoteles y espacios públicos. Desarrollamos, junto con
            nuestros clientes y arquitectos, un amplio proceso de cooperación
            con el que podemos poner en práctica sus visiones creativas y
            diseños funcionales. Siempre desde la perspectiva de nuestros
            valores clave: <b>buen diseño, calidad y funcionalidad.</b>
          </p>
          <p>
            Para nosotros, cada proyecto es un ecosistema que combina un buen
            producto, conocimientos técnicos y experiencia del equipo. Ayudamos
            a seleccionar las herramientas, las gamas de productos y las
            soluciones adecuadas. Realizamos la instalación y ofrecemos un
            servicio de garantía profesional, gracias al cual nuestros clientes
            permanecen con nosotros durante muchos años.
          </p>
          <p>
            A través de nuestras tres exposiciones en Madrid y Barcelona,
            combinamos nuestra pasión por el diseño, en un proceso profesional.
            Nuestras tiendas son espacios de encuentro para nuestros clientes y
            arquitectos, convirtiéndose en un lugar de intercambio de ideas,
            dónde se hacen tangibles los detalles y la calidad.
          </p>
        </Col>
      </Row>
    </Container>
    <div className="height40" />
    <Row>
      <Col sm={4} style={{ padding: 0 }}>
        <GatsbyImage
          image={getImage(data.quienesSomos2)}
          backgroundColor={false}
          alt="daucodesigns"
        />
      </Col>
      <Col sm={4} style={{ padding: 0 }}>
        <GatsbyImage
          image={getImage(data.quienesSomos3)}
          backgroundColor={false}
          alt="daucodesigns"
        />
      </Col>
      <Col sm={4} style={{ padding: 0 }}>
        <GatsbyImage
          image={getImage(data.quienesSomos4)}
          backgroundColor={false}
          alt="daucodesigns"
        />
      </Col>
    </Row>
    <div className="height40" />
    <div className="height40" />
    <Row className="quienes-somos-block">
      <Container>
        <Row>
          <Col sm={2} />
          <Col sm={8}>
            <h4 className="quienes-somos-title">
              <div>
              <div style={{float:"left"}}>02</div><div className="quienes-somos-text">Las mejores marcas internacionales</div>
              </div>
            </h4>
          </Col>
        </Row>
      </Container>
    </Row>
    <Container className="quienes-somos">
      <Row>
        <Col sm={2} />
        <Col sm={8}>
          <p>&nbsp;</p>
          <p>
            Desde el inicio, nuestra inquietud ha sido crear una cartera de
            productos y soluciones que aporten valor para oficinas, hoteles,
            hogares y espacios públicos.
          </p>
          <p>
            Ofrecemos mobiliario profesional de marcas para las que el diseño,
            la calidad, la funcionalidad y el compromiso son fundamentales. Cada
            marca, cada producto, aporta un valor a la colección. Nada está por
            azar. Cada uno tiene su historia y su sentido. Especializados en
            soluciones y productos que mejoran el paisaje, la acústica y la
            privacidad en las oficinas. Sistemas de iluminación, paneles para
            paredes, así como objetos para exteriores. Muebles de interior que
            crean espacios bellos y funcionales. Asientos para el merecido
            descanso. Mesas y sillas ergonómicas.
          </p>
          <p>
            Además, ofrecemos un servicio de personalización y fabricación a
            medida como complemento a los productos estándar.
          </p>
          <p>
            Formar parte de un networking de marcas internacionales nos aporta
            conocimiento sobre tendencias, estilos e innovaciones en el sector
            del mobiliario y del interiorismo. Combinamos estilos y calidades de
            nuestras lineas de contract con nuestras lineas de hogar, para dar
            ese toque cálido que cada vez más necesitan y requieren las personas
            en su entorno.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={2} />
        <Col sm={8}>
          <GatsbyImage
            image={getImage(data.quienesSomos5)}
            backgroundColor={false}
            alt="daucodesigns"
          />
        </Col>
      </Row>
    </Container>
    <div className="height40" />
    <div className="height40" />
    <Row className="quienes-somos-block" >
      <Container>
        <Row>
          <Col sm={2} />
          <Col sm={8}>
            <h4 className="quienes-somos-title">
              <div>
              <div style={{float:"left"}}>03</div><div className="quienes-somos-text">Nuestro equipo: personas para personas</div>
              </div>
            </h4>
          </Col>
        </Row>
      </Container>
    </Row>
    <Container className="quienes-somos">
      <Row>
        <Col sm={2} />
        <Col sm={8}>
          <p>&nbsp;</p>
          <p>
            Daucodesigns no es sólo el mobiliario y los accesorios, sino sobre
            todo, las personas que trabajan para crear una oficina acogedora o
            un espacio funcional para precisamente, las personas.
          </p>
          <p>
            Cada venta, cada proyecto, es gestionado por un equipo, formado e
            implicado que combina los conocimientos técnicos con las últimas
            tendencias y, sobre todo, con las necesidades de los futuros
            usuarios.
          </p>
          <p>
            Lo que nos diferencia, es nuestro compromiso. Compromiso con la
            calidad, con el servicio, con nuestros valores.
          </p>
          <p>
            Hemos amueblado los interiores de empresas grandes y pequeñas, de
            hogares, hoteles y espacios públicos. Siempre contando con un equipo
            profesional desde el primer contacto hasta la logística de última
            milla y montaje final.
          </p>
          <p>
            Escuchamos a nuestros clientes. Compartimos nuestro conocimiento.
            Sumamos ideas.
          </p>
        </Col>
      </Row>
    </Container>
    <div className="height40" />
    <Footer />
  </>
)

export default WhoWeAre

export const query = graphql`
  query pageWhoWeAre {
    quienesSomos1: file(
      relativePath: { eq: "quienes-somos/quienessomos_1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    quienesSomos2: file(
      relativePath: { eq: "quienes-somos/quienessomos_2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    quienesSomos3: file(
      relativePath: { eq: "quienes-somos/quienessomos_3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    quienesSomos4: file(
      relativePath: { eq: "quienes-somos/quienessomos_4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    quienesSomos5: file(
      relativePath: { eq: "quienes-somos/quienessomos_5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
  }
`
